import React from "react"
import Layout from "../components/layout"

const LinksPage = () => (
  <Layout>
    <h2>Lenker</h2>
    <ul>
      <p>
        <a href={"https://www.vegvesen.no/"}>Statens Vegvesen</a>
      </p>
      <p>
        <a href={"http://www.trafoen.no/"}>TRAFO Trafikkprosjekt</a>
      </p>
      <p>
        <a href={"https://ntsf.no/"}>Norges Trafikkskoleforbund</a>
      </p>
      <p>
        <a href={"https://www.tryggtrafikk.no/"}>Trygg Trafikk</a>
      </p>
      <p>
        <a href={"https://trafikkforum.no/"}>Trafikkforum</a>
      </p>
    </ul>
  </Layout>
)

export default LinksPage
